import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { monitoringPresetKeys } from "src/data/profileSettings/queries/monitoringPresetQueryCache"
import {
  TNoiseMonitoringPresetPostResponse,
  TPostPreset,
} from "src/data/profileSettings/types/monitoringPresetTypes"
import { useMinutPatch, useMinutQuery } from "src/hooks/minutQuery"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchNoiseMonitoringPresets({ orgId }: { orgId: string }) {
  return useMinutQuery<
    `/organizations/{organization_id}/profiles/noise`,
    "403"
  >({
    queryKey: monitoringPresetKeys.noisePresets(),
    queryPath: `/organizations/${orgId}/profiles/noise`,
    apiPath: `/organizations/{organization_id}/profiles/noise`,
  })
}

export function usePostNoiseMonitoringPresets({ orgId }: { orgId: string }) {
  const queryClient = useQueryClient()

  async function postNoiseMonitoringPresets(data: TPostPreset) {
    const response = await minutApiHttpClient.post<
      TPostPreset,
      AxiosResponse<TNoiseMonitoringPresetPostResponse>
    >(`${API_DEFAULT}/organizations/${orgId}/profiles/noise`, data)

    return response.data
  }

  return useMutation<
    TNoiseMonitoringPresetPostResponse,
    AxiosError,
    TPostPreset
  >({
    mutationFn: postNoiseMonitoringPresets,
    onSuccess: () => {
      return queryClient.invalidateQueries(monitoringPresetKeys.noisePresets())
    },
  })
}

// ts-prune-ignore-next
export function usePatchNoiseMonitoringPreset() {
  const queryClient = useQueryClient()

  return useMinutPatch<
    `/organizations/{organization_id}/profiles/noise/{profile_id}`,
    `400`
  >({
    pathFn: ({ organization_id, profile_id }) =>
      `/organizations/${organization_id}/profiles/noise/${profile_id}`,
    options: {
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries(monitoringPresetKeys.noisePresets()),
        ])
      },
    },
  })
}

type TDeletePreset = { presetId: string; orgId: string }

export function useDeleteNoiseMonitoringPreset() {
  const queryClient = useQueryClient()

  async function deleteNoiseMonitoringPreset({
    presetId,
    orgId,
  }: {
    presetId: string
    orgId: string
  }) {
    const response = await minutApiHttpClient.delete<
      TDeletePreset,
      AxiosResponse
    >(`${API_DEFAULT}/organizations/${orgId}/profiles/noise/${presetId}`)

    return response.data
  }

  return useMutation<AxiosResponse, AxiosError, TDeletePreset>({
    mutationFn: deleteNoiseMonitoringPreset,
    onSuccess: () => {
      return queryClient.invalidateQueries(monitoringPresetKeys.noisePresets())
    },
  })
}

export function useFetchNoiseMonitoringPreset({
  orgId,
  noisePresetId = "",
}: {
  orgId: string
  noisePresetId?: string
}) {
  return useMinutQuery<
    `/organizations/{organization_id}/profiles/noise/{profile_id}`,
    "403"
  >({
    apiPath: `/organizations/{organization_id}/profiles/noise/{profile_id}`,
    queryPath: `/organizations/${orgId}/profiles/noise/${noisePresetId}`,
    queryKey: monitoringPresetKeys.noisePreset(noisePresetId),
    options: { enabled: !!orgId && !!noisePresetId },
  })
}

export function useFetchIndoorClimateMonitoringPresets() {
  async function fetchIndoorClimateMonitoringPresets() {
    const response = {
      profiles: icmProfiles,
      paging: {
        total: 2,
        limit: 20,
        offset: 0,
      },
    }
    return Promise.resolve(response)
  }

  return useQuery({
    queryKey: monitoringPresetKeys.icmPresets(),
    queryFn: fetchIndoorClimateMonitoringPresets,
  })
}

//#region
// Using static data for ICM at the moment. Will swap this out with real data later when the
// backend is ready
const icmProfiles = [
  {
    id: "1",
    name: "Standard indoor climate Profile",
  },
  {
    id: "2",
    name: "Strict indoor climate Profile",
  },
]

//#endregion
